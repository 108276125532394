<template>
    <columns>
        <column class="is-3 is-flex is-align-items-center">
            <p class="has-text-weight-bold">
                {{ property.name }}
            </p>
        </column>
        <column>
            <text-input 
                classes="is-rounded"
                :value="propertyValue"
                @input="updatePropertyValue"
            />
        </column>
    </columns>
</template>
<script>
import { mapGetters } from 'vuex'
import find from 'lodash/find'

export default {

    props: {
        property: {
            type: Object,
            default: () => ({})
        }
    },

    methods: {
        updatePropertyValue(value) {
            this.$store.commit('equipment/updatePropertyValue', {
                uuid: this.property.uuid,
                value
            })
        }
    },

    computed: {
        ...mapGetters('equipment', [
            'equipment'
        ]),
        propertyValue() {
            const property = find(this.equipment.properties, property => property.uuid === this.property.uuid)

            return property ? property.value : ''
        }
    }
}
</script>