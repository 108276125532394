<template>
    <loader v-bind="{ loading }">
        <form>
            <columns>
                <column>
                    <data-selector
                        searchable
                        :items="customer_list"
                        v-model="customer_uuid"
                        value-key="uuid"
                        label-key="name"
                        classes="is-rounded is-medium">
                        Customer
                    </data-selector>
                </column>
                <column>
                    <data-selector
                        required
                        :items="locations"
                        :value="equipment.location_uuid"
                        @input="updateLocation"
                        :error="$root.errors.location_uuid"
                        value-key="uuid"
                        label-key="name"
                        classes="is-rounded is-medium">
                        Location
                    </data-selector>
                </column>
                <column>
                    <data-selector
                        required
                        :items="statuses"
                        :value="equipment.status"
                        :error="$root.errors.status"
                        @input="updateStatus"
                        classes="is-rounded is-medium">
                        Status
                    </data-selector>
                </column>
            </columns>
            <columns>
                <column>
                    <text-input 
                        required
                        classes="is-medium is-rounded" 
                        description="A human friendly name for this piece of equipment"
                        :value="equipment.name" 
                        @input="updateName"
                        :error="$root.errors.name">
                        Name
                    </text-input>
                </column>
                <column>
                    <text-input 
                        required
                        classes="is-medium is-rounded" 
                        description="A unique identifier for this piece of equipment"
                        :value="equipment.code.value" 
                        @input="updateCodeValue"
                        :error="$root.errors['code.value']">
                        Code
                    </text-input>
                </column>
                <column>
                    <data-selector
                        required
                        classes="is-medium is-rounded" 
                        description="Categorise the piece of equipment"
                        :items="equipment_classification_list"
                        label-key="name" 
                        value-key="uuid" 
                        :value="equipment.classification_uuid"
                        @input="updateClassification" 
                        :error="$root.errors.classification_uuid">
                        Classification
                    </data-selector>
                </column>
                <column>
                    <data-selector 
                        classes="is-medium is-rounded" 
                        description="Optionally assign a user"
                        :items="user_list" 
                        label-key="full_name"
                        value-key="uuid" 
                        :value="equipment.user_uuid" 
                        @input="updateUser" 
                        :error="$root.errors.user_uuid">
                        Assigned User
                    </data-selector>
                </column>
            </columns>

            <div v-if="activeClassification">
                <columns>
                    <column>
                        <h4 class="title is-4">Properties</h4>
                    </column>
                </columns>
                <columns v-for="property in activeClassification.meta" :key="property.uuid">
                    <column>
                        <edit-equipment-property :property="property" />
                    </column>
                </columns>
            </div>

            <columns>
                <column>
                    <submit-button class="is-medium is-rounded" :working="working" @submit="save">
                        Save
                    </submit-button>
                </column>
            </columns>
        </form>
    </loader>
</template>
<script>
import find from 'lodash/find'
import { mapGetters, mapMutations } from 'vuex'
import EditEquipmentProperty from './partials/EditEquipmentProperty.vue'

export default {

    components: {
        EditEquipmentProperty
    },

    data: () => ({
        loading: true,
        working: false,
        customer_uuid: '',
        statuses: [
            { value: 'operational', label: 'Operational' },
            { value: 'faulty', label: 'Faulty' },
        ]
    }),

    async beforeCreate() {
        this.$store.commit('equipment/clearEquipment')
        await this.$store.dispatch('customer/loadCustomerList')
        await this.$store.dispatch('equipmentClassification/loadList')
        await this.$store.dispatch('user/loadList')

        this.loading = false
    },

    methods: {
        ...mapMutations('equipment', [
            'updateName',
            'clearEquipment',
            'updateClassification',
            'updateUser',
            'updateCodeValue',
            'updateLocation',
            'updateStatus'
        ]),
        save() {
            this.working = true
            this.$store.dispatch('equipment/create').then(() => {
                this.working = false
                this.$toast.success('Saved')
                this.clearEquipment()
                this.customer_uuid = ''
            }).catch(() => this.working = false)
        }
    },

    computed: {
        ...mapGetters('equipment', [
            'equipment'
        ]),
        ...mapGetters('equipmentClassification', [
            'equipment_classification_list'
        ]),
        ...mapGetters('user', [
            'user_list'
        ]),
        ...mapGetters('customer', [
            'customer_list'
        ]),
        activeClassification() {
            return find(this.equipment_classification_list, classification => classification.uuid === this.equipment.classification_uuid)
        },
        activeCustomer() {
            return this.customer_uuid ? find(this.customer_list, customer => customer.uuid === this.customer_uuid) : null
        },
        locations() {
            return this.activeCustomer ? this.activeCustomer.locations : []
        }
    }

}
</script>